.leaguesContainer {
  //padding-left: 14px;
}

.calendarIcon {
  font-size: 11px;
  padding: 4.5px 7px;
  background: white;
  border-radius: 4px;
  margin: 14px;
  cursor: pointer;
  float: left;
  svg {
    stroke: #2D2A2B;
    height: 20px;
    width: 20px;
  }
}
