
@import './misc/_variables.scss';

:root {
    --loading-grey: #ededed;
}

body{
    background-color: $main-bg-color !important;
    margin: 0;
    font-family: 'Jaldi', sans-serif !important;
}
.header{
    height: 43px;
    background-color: $black;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    position: sticky;
    top: 0;
    z-index: 1100;
    .logo_container{
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .header_lang_change{
        img{
            height: 19px;
            width: auto;
            margin: auto;
            cursor: pointer;
        }
    }
    .logo_img {
        background-color: $red;
        height: 25px;
        width: 25px;
        border-radius: 4px;
        text-align: center;
        display: flex;
        align-items: center;
        img {
            height: 19px;
            width: auto;
            margin: auto;
        }
    }
    h4{
        color: $white;
        margin: 0;
        padding: 0;
        font-size: 15px;
    }
}

.homepage_layout{
    .layout_content{
        max-width: 640px;
        margin: 0 auto 75px;
    }
}
.first_slider{
    padding: 0px 14px;
    margin-top: 10px;
    .first_slider_item{
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        .slider_strip{
            color: $white;
            width: 30px;
            position: relative;
            p{
                // transform: rotate(270deg);
                line-height: 1;
                // height: 30px;
                position: absolute;
                left: -43px;
                // margin: 0;
                // padding: 0;
                // bottom: 0;
                // top: 44px;
                width: 116px;
                // display: flex;
                font-size: 13px;
                // align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                // updatedStyles
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
                // text-align: center;
            }
            img{
                line-height: unset;
                position: absolute;
                left: 50%;
                margin: 0;
                padding: 0px;
                bottom: 8px;
                width: 18px;
                right: 50%;
                transform: translate(-50%, 0%);
            }
        }
        .slider_content{
            height: 122px;
            overflow: hidden;
            flex: 1;
            padding: 9px;
            background-position: top !important;

            display: flex;
            flex-direction: column;
            align-items: center;
            .top_section{
                display: flex;
                align-items: center;
                color: $white;
                gap: 10px;
                .logo_section{
                    background-color: #E40000;
                    border-radius: 2px;
                    height: 18px;
                    padding: 6px 6px 3px 4px;
                    display: flex;
                    align-items: center;
                    span{
                        font-size: 8px;
                        position: relative;
                        top: -1px;
                    }
                    img{
                        // width: 15px;
                        margin-right: 6px;
                    }
                }                
                h5{
                    font-size: 10px;
                    margin: 0;
                }
                p{
                    font-size: 10px;
                    margin: 0;
                }
            }
            .team_section{
                display: flex;
                align-items: center;
                gap: 10px;
                color: $white;
                margin: 10px 0px 0px;
                div {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    h5{
                        font-size: 14px;
                        margin: 0;
                        font-weight: bold;
                    }
                    img {
                        width: 15px;
                    }
                }
            }
            .dec{
                margin: 5px 0px 8px;
                width: 70%;
                text-align: center;
                p {
                    font-size: 10px;
                    color: $white;
                    margin: 0;
                    line-height: 1;
                    min-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            .odds_section{
                background: $green;
                border-radius: 4px;
                height: 26px;
                min-height: 26px;
                width: 80%;
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: space-between;
                padding: 0px 10px;
                color: $white;
                cursor: pointer;
                p{
                    font-size: 12px;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                h5 {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }
}

.league_tabs{
    .league_tab_slider{
        display: flex;
        ul {
            list-style-type: none;
            display: flex;
            gap: 10px;
            overflow-y: hidden;
            overflow-x: auto;
            padding: 0;
            margin: 14px 0px;
            &[data-scrollable]::-webkit-scrollbar {
                display: none;
                -webkit-appearance: none;
            }
            :last-child{
                margin-right: 14px;
            }
            li {
                padding: 4px 15px;
                background-color: $white;
                border-radius: 4px;
                font-size: 13px;
                cursor: pointer;
                line-height: 21px;
                white-space: nowrap;
                &.selected{
                    font-weight: bold;
                }
            }
        }
    }
}

.lig_slider_one  {
    .swiper-slide {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 14px;
    }
}
.lig_slider{
    .swiper{
        &.swiper-horizontal{
            padding: 0px 14px;
            margin-bottom: 14px;
        }
    }
    .swiper-slide{
        &:first-child{
            // margin-left: 14px;
            padding: 0;
            margin-bottom: 0px;
        }
    //   width: auto!important;  
    }
    // .lig_slider_item
    .lig_slider_item{
        position: relative;
        min-height: 166px;
        height: auto;
        // min-width: 302px;
        // width: 302px;
        border-radius: 4px;
        padding: 5px 16px;
        // background-color: #37003E;
        background-repeat: no-repeat;
        background-position: right -70%;
        .live{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 8px;
            left: 0;
            height: 20px;
            background: linear-gradient(45deg, #E40000 25%, #F3E5AB 50%, #E40000 75%);
            background-size: 200% auto;
            color: white;
            animation: shimmer 5s infinite linear;            
            border-radius: 0px 2px 2px 0px;
            p{
                padding: 0px 10px 0px 10px;
                color: #FFFFFF;
                font-size: 12px;
                line-height: 21px;                
                margin-bottom: 0px;                
            }
            @keyframes shimmer {
                0% { background-position: 200% center; }
                to { background-position: -200% center; }
              }            
        }
        .title{
            text-align: center;
            color: $white;
            font-size: 13px;
            font-weight: bold;
            margin: 0;
            padding-top: 5px;
        }
        .team_section{
            display: flex;
            justify-content: space-around;
            align-items: center;
            // gap: 30px;
            .team_content{
                text-align: center;
                flex-basis: 40%;
                img {
                    height: 53px;
                    margin-bottom: 5px;
                }
                p {
                    margin: 0;
                    color: $white;
                    line-height: 1;
                    font-size: 13px;
                    min-height: 26px;
                    word-break: break-word;
                }
            }
            .team_time{
                margin-top: 10px;
                text-align: center;
                flex-basis: 25%;
                p {
                    margin: 0;
                    font-size: 30px;
                    color: $white;
                }
                span{
                    margin: 0;
                    font-size: 12px;
                    line-height: 21px;
                    color: $white;
                }
            }
            .team_score{
                text-align: center;
                color: $white;
                h2 {
                    margin: 0;
                    font-size: 30px;
                    line-height: 1;
                    padding-top: 4px;
                    padding-bottom: 2px;
                }
                p {
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
        .win_title{
            padding-top: 3px;
            p{
                margin: 2px;
                text-align: center;
                color: $white;
                font-size: 13px;
            }
        }
        .odd_items{
            display: flex;
            margin-top: 5px;
            gap: 10px;
            &.multiple{
                justify-content: center;
                gap: 20px;
                margin: 10px 9px 0px;
                .odd_item {
                    width: 65px;
                    padding: 1px 5px;
                    justify-content: right;
                    gap: 0px;
                    p:first-child{
                        min-width: 10px;
                        margin: unset;
                        text-align: left;
                    }
                    p:last-child{
                        font-weight: bold;
                        min-width: 40px;
                        margin: unset;
                        text-align: right;
                        margin-right: 3px;
                    }
                }
            }
            &:not(.multiple){
                .odd_item {
                    :first-child{
                        font-weight: bold;
                    }
                }
            }
            .odd_item {
                background: $white;
                border-radius: 4px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 1px 10px;
                gap: 15px;
                cursor: pointer;
                &.selected{
                    background: #1C1A1B;
                    color: $white
                }
                
                P{
                    margin: 0;
                    font-size: 13px;
                    line-height: 25px;
                }
            }
        }
    }
}
.popular_odds_container {
    margin: 0px 14px 14px 14px;
    background: $white;
    border-radius: 4px;
    .popular_odd_header {   
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 30px;
        border-bottom: 1px solid #F2F2F2;
        h2 {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
        }
        .share_icon{
            display: flex;
            align-items: center;
            cursor: pointer;
            .share_icons_line{
                display: flex;
                align-items: center;
                gap: 10px;
                height: 13px;
                img{                    
                    width: 26px;
                    height: 26px;
                    &:nth-child(4){
                        width: 24px;
                        height: 24px;
                    }                    
                }
                svg{
                    fill: #A2A2A2;
                    height: 12px;
                    width: 14px;                    
                }
            }
        }
        svg {
            fill: #A2A2A2;
            height: 20px;
            // width: 100%;
            padding-left: 40px;
            stroke: #A2A2A2;
        }
    }
    .popular_matches_content{
        padding: 0px 20px 10px;
        .popular_match_item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0px;
            .left_content{
                display: flex;
                justify-content: center;
                gap: 0px;
                width: 100%;
                padding-right: 5px;
                overflow: hidden;
                .team_section{
                    &:nth-child(1){
                        justify-content: end;
                        text-align: end;
                    }
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    flex-basis: 40%;
                    overflow: hidden;
                    img {
                        height: 20px;
                    }
                    p {
                        font-size: 12px;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #000000;
                    }
                }
                .time_section{
                    text-align: center;
                    flex-basis: 20%;
                    p{
                        &:first-child{
                            font-weight: bold;
                            line-height: 1;
                        }
                        margin: 0;
                        color: #707070;
                        font-size: 11px;
                    }
                }
            }
            .odd_button{
                display: flex;
                background-color: #F0F0F0;
                border-radius: 4px;
                padding: 0px 11px 0px 5px;
                gap: 10px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                width: 66px;
                cursor: pointer;
                &.selected{
                    background-color: #2D2A2B;
                    color: $white;
                }
                :last-child{
                    font-weight: bold;
                    line-height: 1;
                }
                p{
                    &:first-child{
                        line-height: 1;
                        text-align: center;
                        width: 20px;
                    }
                    margin: 0;
                    font-size: 13px;
                }
            }
        }
    }
}

.calendar {
    display: flex;    
    align-items: center;    
    justify-content: flex-end;
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    font-size: 11px;
    opacity: 1;    
    border-bottom: 1px solid #F2F2F2;
    padding: 0 20px 15px;
    .month_name{
        display: flex;
        // flex-basis: 20%;
        align-items: center;
        width: 61px;
    }
    .day_date_name{
        display: flex;
        // flex-basis: 80%;
        align-items: center;
        width: calc(100% - 61px);
        cursor: pointer;
        > .date{
            //margin: 15px 0 0 20px;
            text-align: center;
        }
    }
    svg{
        stroke: #2D2A2B;        
        height: 20px;
        margin-top: 18px;
        // margin-left: 20px;
        margin-right: 5px;
        width: 20px;
    }
    h1{
        text-align: left;
        letter-spacing: 0px;
        color: #2D2A2B;
        opacity: 1;
        font-size: 11px;
        margin-top: 25px;
    }
    .next_dates_column {
        display: flex;        
        align-items: center;
        gap: 0px
    }
    .date {
        margin-top: 15px; 
        padding: 3px 3px;
        border: 1px solid transparent;
        border-radius: 4px;
        width: 34px;
        height: 34px;
        text-align: center;
        min-width: 34px;
        span{
            display: block;
            line-height: 1.2;
        }
        .day_of-week{
            text-align: center;
        }
        .date_number{
            letter-spacing: 0px;
            color: #2D2A2B;
            opacity: 1;
            font-size: 11px;
            // font-weight: bold;
            text-align: center;
            &.bold{
                font-weight: bold;
            }
        }
        .number{
            color: #2D2A2B;
            opacity: 1;
            font-size: 11px;
            // font-weight: bold;
            &.bold{
                font-weight: bold;
            }
        }
    }  
    .selected {
        border-color: #2D2A2B;
    }
}

.multi_bet_odds_container {
    margin: 14px 14px 14px 14px;
    background: $white;
    border-radius: 4px;
    .range_container{
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // padding: 12px 20px 0px 27px;
        padding: 12px 30px 0px 30px;
        .winning_more{
            text-align: center;    
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-size: 12px;
            flex-basis: 35%;
            // margin: 10px 0px 0px 20px;
            p{
                margin: 0px;                
            }
            .winning_more_value {
                text-align: center;                
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                font-size: 18px;
                margin: -5px;
            }
        }        
        .select_range{
            // align-self: flex-end;
            text-align: center;            
            // height: 15px;
            flex-basis: 60%;
            .range_style{
                -webkit-appearance: none;
                appearance: none;                
                width: 80%;
                height: 4px;
                background: #00CA6B;
                outline: none;
                border-radius: 50px;
                cursor: pointer;
            }
            .range_style::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 15px;
                height: 15px;
                background: #00CA6B;
                border-radius: 50%;
                cursor: pointer;
              }
        }
    }
    .add_bet{
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 8px 5px;
        margin: 0px 20px 0px;
        border-bottom: 1px solid #F8F8F8;
        label{
            text-align: center;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;
            padding: 5px;
            font-size: 12px;
            // border-bottom: 1px solid #F2F2F2;
            // height: 21px;        
            svg{
                height: 31px;
                width: 12px;                 
            }
        }
    }
    .total_odds_details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        .total_odds{
            display: flex;
            text-align: center;    
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-size: 12px;
            // margin: 10px 0px 0px 20px;
            p{
                margin: 0px;   
                font-size: 18px;             
                color: #707070;
            }
            .total_odds_value {
                text-align: center;                
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                font-size: 18px;                
            }
        }        
        .total_win{
            display: flex;
            text-align: center;    
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-size: 12px;
            // margin: 10px 0px 0px 20px;
            p{
                margin: 0px;  
                font-size: 18px;             
                color: #707070;              
            }
            .total_wins_value {
                text-align: center;                
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                font-size: 18px;                
            }
        }
    }
    .multi_bet_button{
        text-align: center;
        button{
            text-align: center;            
            letter-spacing: 0px;
            color: #707070;
            background-color: white;
            font-size: 12px; 
            // border: 1px solid #A2A2A2;
            border: none;
            border-radius: 2px;
            opacity: 100;
            // height: 27px;
            // min-width: 117px;
            // margin-bottom: 33px;
            // margin-top: 21px;
            position: relative;
            .loading_icon {
                display: inline-block;
                animation: spin 1s infinite linear;
                color: #707070;
                // position: absolute;
                // left: 0;
                svg{
                  fill: #A2A2A2;
                  height: 18px;
                  width: 20px;
                }
              }
              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            span{
                color: #707070;
            }
            svg{
                fill: #A2A2A2;
                height: 15px;
                width: 19px
            }
            img{
                fill: #A2A2A2;
                height: 20px;
                width: 21px
            }
        }
    }
    .add_to_collection_button{
        text-align: center;
        button{
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;            
            letter-spacing: 0px;
            color: #707070;
            background-color: white;
            font-size: 12px; 
            border: 1px solid #A2A2A2;
            border-radius: 2px;
            opacity: 100;
            height: 27px;
            min-width: 117px;
            margin-bottom: 33px;
            margin-top: 21px;
            position: relative;            
            .plus_sign{
                line-height: 1;
                position: relative;
                top: -3px;
                color: #A2A2A2;
                width: 10px;                
                font-size: 20px;
                margin: 0;            
            }
            .add_to_collection_label{
                color: #707070;
                font-size: 12px;
                margin: 0;
            }
        }
        button:active {
            background-color: darkgray;
            color: white;
            .plus_sign, .add_to_collection_label {
                color: white;
            }
        }
    }
    .multi_bet_odd_header {   
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 30px;
        border-bottom: 1px solid #F2F2F2;
        h2 {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
        }
        .share_icon{
            display: flex;
            align-items: center;
            cursor: pointer;
            .share_icons_line{
                display: flex;
                align-items: center;
                gap: 10px;
                height: 13px;
                img{                    
                    width: 26px;
                    height: 26px;
                    &:nth-child(4){
                        width: 24px;
                        height: 24px;
                    }                    
                }
                svg{
                    fill: #A2A2A2;
                    height: 12px;
                    width: 14px;                    
                }
            }
        }
        svg {
            fill: #A2A2A2;
            height: 20px;
            // width: 100%
            padding-left: 40px;
            stroke: #A2A2A2;
        }
    }
    .multi_bet_matches_content{
        padding: 0px 20px 0px;
        margin-top: 26px;
        min-height: calc(100vh - 406px);
        .multi_bet_match_item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 5px;
            border-bottom: 1px solid #F8F8F8;
            svg{                
                width: fit-content;
                min-width: 90px;
                height: 17px;
                display: block;
                margin: 0;
                max-width: 10px;
                cursor: pointer;
                // height: 30px;
                // margin-right: 5px;
                // margin-left: 15px;
                // max-width: 20px;
            }
            .lock_svg_icon,
            .unlock_svg_icon{
                // width: fit-content;
                // min-width: 15px;
                height: 20px;
                display: block;
                margin: 0px 20px;
                // max-width: 10px;
                object-fit: contain;
                width: 20px;
                cursor: pointer;
            }
            .left_content{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 0px;
                width: 100%;
                align-items: center;
                overflow: hidden;
                .team_section{
                    &:nth-child(1){
                        justify-content: end;
                        text-align: end;
                        margin-right: 5px;
                    }
                    display: flex;
                    // align-items: center;
                    gap: 5px;
                    // flex-basis: 40%;
                    flex-basis: auto;
                    align-items: center;
                    overflow: hidden;
                    line-height: 30px;
                    img {
                        height: 30px;
                        width: 30px;
                    }
                    svg{
                        // height: 20px;
                        min-width: 20px;
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    p {
                        font-size: 14px;
                        margin: 0;
                        line-height: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 30px;
                    }
                }                
                .time_section{
                    text-align: center;
                    flex-basis: 20%;
                    p{
                        &:first-child{
                            font-weight: bold;
                            line-height: 1;
                        }
                        margin: 0;
                        color: #707070;
                        font-size: 11px;
                    }
                }
                .text_content{
                    width: 100%;
                    p {
                        margin: 0;
                        font-size: 14px;
                        text-align: left;
                        height: 30px;
                        /* color: black; */
                        letter-spacing: 0px;
                        line-height: 30px;
                        color: #000000;
                        opacity: 1;
                        span{
                            font-weight: bold;
                            font-size: 14px;
                            padding-left: 10px;
                            letter-spacing: 0px;
                            color: #000000;
                        }
                    }
                }
            }
            .odd_button{
                display: flex;
                background-color: #F0F0F0;
                border-radius: 4px;
                padding: 0px 11px 0px 5px;
                gap: 10px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                width: 66px;
                cursor: pointer;
                &.selected{
                    background-color: #2D2A2B;
                    color: $white;
                }
                :last-child{
                    font-weight: bold;
                    line-height: 1;
                }
                p{
                    &:first-child{
                        line-height: 1;
                        text-align: center;
                        width: 20px;
                    }
                    margin: 0;
                    font-size: 13px;
                }
            }
        }
    }
}
.news_modal_container{
    .modal-dialog .modal-body {
        max-height: 600px;
        overflow: auto;
    }
    .modal-dialog > .modal-content {
        margin-bottom: 60px;
    }
    .modal_content {
        background-color: white;
        // padding: 20px;
        border-radius: 4px;
        gap: 7px;
        h3{
            text-align: center;
            font-weight: bold;            
            font-size: 20px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            margin: 10px 0 15px 0;
        }
        img{
            // height: 155px;
            // padding: 12px 11px 0px 11px;
            width: 100%;
        }
        p{
            font-size: 16px;
            text-align: center;
            // margin: 0 14px 10px 14px;
            line-height: 1;
            span{
                background: transparent !important;
            }
            strong{
                background: transparent !important;
            }
        }
        .close_button{
            margin-top: 4px;
            button{
                background: #F0F0F0 0% 0% no-repeat padding-box;
                opacity: 1;
                width: 100%;
                color: #000000;
                height: 37px;
                // margin-top: 4px;
                font-size: 16px;
                font-weight: bold;
                border: none;
                // margin: 0 11px 12px 11px;
            }
        } 
    }
}

.news_slider{
    padding: 0px 14px;
    margin-top: 10px;
    .news_slider_item{
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        .news_slider_strip{
            color: $white;
            width: 30px;
            position: relative;
            p{
                transform: rotate(270deg);
                line-height: unset;
                height: 30px;
                position: absolute;
                left: -46px;
                margin: 0;
                padding: 0;
                bottom: 0;
                top: 39px;
                width: 122px;
            }
        }
        .news_slider_content{            
            overflow: hidden;
            flex: 1;
            // height: 280px;
            padding-bottom: 15px;
            margin-bottom: 10px;
            background: #FFFFFF;
            background-position: top !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            opacity: 1;
            .top_section{
                display: flex;
                align-items: center;
                color: #000;
                flex-direction: column;
                // gap: 7px;                
                img {
                    // height: 155px;
                    padding: 12px 13px 12px 13px;
                    width: 100%;
                    border-radius: 15px;
                }
                h5{
                    font-size: 10px;
                    margin: 0;
                }
                .news_description{
                    font-size: 16px;
                    text-align: left;
                    margin: 0 14px 4px 14px;
                    line-height: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;                    
                }
                .no_image{
                    padding: 12px 0px 0px 0px;
                }
            }            
            .news_button{
                margin-top: 4px;
                width: 100%;
                padding: 0 14px;
                button{
                    background: #F0F0F0 0% 0% no-repeat padding-box;
                    opacity: 1;
                    width: 100%;
                    color: #000000;
                    height: 37px;
                    // margin-top: 4px;
                    font-size: 16px;
                    font-weight: bold;
                    border: none;
                }
            }           
        }
    }
}

@media(min-width: 500px){
    .news_slider{
        display: flex;
        gap: 0px 10px;
        flex-wrap: wrap;
    }
    .news_slider .news_slider_item .news_slider_content{
        max-width: 333px;
    }      
}

.odd_section_container {
    .odd_section_header{  
        h2{
            text-align: left;    
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-size: 16px;
            font-weight: bold;
            margin: 10px 0px 0px 15px;
        }
    }
}

.odds_section_container {
    margin: 0px 14px 14px 14px;
    background: $white;
    border-radius: 4px;    
    hr{
        border: 1px solid #F2F2F2;
        opacity: 1;
        height: 0px
    }
    .odds_section_content{
        .no_data_found{
            text-align: center;
            padding: 10px;
            font-size: 14px;
            height: 150px;
            padding-top: 55px;
        }
        // padding: 0px 20px 10px;
        .odds_section_item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin: 15px 0px;
            padding: 15px 20px;
            border-bottom: 1px solid #F2F2F2;
            .left_content{
                display: flex;
                //justify-content: center;
                gap: 0px;
                width: 100%;
                .team_section{
                    &:nth-child(1){
                        justify-content: end;
                        max-width: 70px;
                        text-align: end;
                    }
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    // flex-basis: 40%;
                    img {
                        height: 43px;
                        width: 43px;
                    }
                    p {
                        font-size: 12px;
                        margin: 0;
                    }
                }
                .team_section img:nth-child(1) {
                    position: relative;
                    z-index: 2;
                    top: -13px;
                    left: 16px;
                  }                  
                .team_section img:nth-child(2) {
                    position: relative;
                    left: -17px;
                    z-index: 1;
                    top: 14px;
                  }
                .team_section .skeleton_img_1 {
                    position: relative;
                    z-index: 2;
                    top: -13px;
                    left: 0px;
                  }                    
                .team_section .skeleton_img_2 {
                    position: relative;
                    left: -17px;
                    z-index: 1;
                    top: 14px;
                  }
                .time_section{
                    text-align: center;
                    flex-basis: 20%;
                    p{
                        &:first-child{
                            font-weight: bold;
                            line-height: 1;
                        }
                        margin: 0;
                        color: #707070;
                        font-size: 11px;
                    }
                }
                .odd_section_details{ 
                    .event_kick_off{   
                        display: flex;            
                            .event_details{
                                text-align: left;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 0px;
                                color: #707070;
                                opacity: 1;
                                margin: 0px;
                                max-width: calc(100% - 53px);
                                display: block;
                                white-space: normal;
                                word-break: break-all;
                            }
                            .kick_off_time{
                                text-align: left;
                                font-size: 10px;
                                letter-spacing: 0px;
                                color: #707070;
                                opacity: 1;
                                margin: 0px;
                                display: flex;
                                line-height: 1;
                                padding-left: 10px;
                                min-width: 55px;
                                padding-top: 3px;
                            }
                        }
                    .team_details{
                        text-align: left;
                        font-size: 12px;
                        font-weight: bold;                        
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        margin: 0px;
                    }
                    .stats{
                        text-align: left;
                        font-size: 12px;                        
                        letter-spacing: 0px;
                        color: #707070;
                        opacity: 1;
                        margin: 0px;
                        padding-right: 10px;
                        line-height: 1;
                    }
                }
            }
            .odd_button{
                display: flex;
                background-color: #F0F0F0;
                border-radius: 4px;
                padding: 0px 11px 0px 5px;
                gap: 10px;
                height: 30px;
                align-items: center;
                justify-content: center;
                width: 66px;
                cursor: pointer;
                &.odd-only{
                  padding-right: 5px;
                  width: 80px;
                }
                &.selected{
                    background-color: #2D2A2B;
                    color: $white;
                }
                :last-child{
                    font-weight: bold;
                    line-height: 1;
                }
                p{
                    &:first-child{
                        line-height: 1;
                        text-align: center;
                        width: 20px;
                    }
                    margin: 0;
                    font-size: 13px;
                }
            }
        }
    }
}

.tip_collection_modal {
    .modal-dialog-centered{
        align-items: end !important;
    }
    .modal-content{
        margin-bottom: 54px;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
    }
    .modal-header{
        padding: 10px 20px;
        border-color: #F2F2F2;
        .modal-title{
            font-size: 16px;
            font-weight: bold;
        }
        .btn-close{
            font-size: 12px;
        }
    }
    .modal-body {
    
        .odds_container {
            margin: 5px;
            background: $white;
            border-radius: 4px;
            .matches_content{
                // padding: 0px 20px 10px;
                .popular_match_item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 16px;
                    border-bottom: 1px solid #F8F8F8;
                    .left_content{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        gap: 0;
                        width: 100%;
                        overflow: hidden;
                        .team_section{
                            &:nth-child(1){
                                justify-content: flex-start;
                                padding-right: 10px;
                                text-align: end;
                            }
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            flex-basis: auto;
                            overflow: hidden;
                            img {
                                height: 20px;
                            }
                            p {
                                font-size: 12px;
                                margin: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            svg{
                                height: 20px;
                                width: 20px;
                                margin-left: 0px;
                                margin-right: 0px;
                            }
                        }
                        .text_content{
                            width: 100%;
                            p {
                                margin: 0;
                                font-size: 13px;
                                text-align: left;
                                height: 22px;
                                /* color: black; */
                                letter-spacing: 0px;
                                line-height: 22px;
                                color: #000000;
                                opacity: 1;
                                span{
                                    font-weight: bold;
                                    font-size: 13px;
                                    padding-left: 10px;
                                    letter-spacing: 0px;
                                    color: #000000;
                                }
                            }
                        }
                        .main_team_section{
                            display: flex;
                            justify-content: flex-start;
                        }
                    }
                    .odd_button{
                        display: flex;
                        background-color: #F0F0F0;
                        border-radius: 4px;
                        padding: 0px 11px 0px 5px;
                        gap: 10px;
                        height: 30px;
                        align-items: center;
                        justify-content: space-between;
                        width: 66px;
                        min-width: 66px;
                        cursor: pointer;
                        &.selected{
                            background-color: #2D2A2B;
                            color: $white;
                        }
                        :last-child{
                            font-weight: bold;
                            line-height: 1;
                        }
                        p{
                            &:first-child{
                                line-height: 1;
                                text-align: center;
                                width: 20px;
                            }
                            margin: 0;
                            font-size: 13px;
                        }
                    }
                    .remove_odds{
                        p{
                            font-size: 13px;
                            letter-spacing: 0px;
                            color: #000000;
                            opacity: 1;
                            text-align: left;
                            line-height: 1;
                            margin: 0;
                            cursor: pointer;
                        }
                    }
                }
               
            }
        }
        .loading_container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center; 
            background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity value as desired */
            z-index: 9999; /* Ensure the loading container is on top of other elements */             
            .loading_text {
                font-size: 16px;
                font-weight: bold;
            }
        }
        .empty_text{
            text-align: center;
            font-size: 14px;
            color: #A2A2A2;
            padding: 50px 0px;
        }
        .total_odds{
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 5px;
            margin-top: 20px;
            p{
                margin: 0;
                font-size: 18px;
                color: #707070;
                line-height: 2;
            }
            h5 {
                margin: 0;
                color: #000000;
                font-size: 18px;
                line-height: 2;
                font-weight: bold;
            }
        }
        .tip_collection_button{
            display: flex;
            gap: 10px;
            justify-content: center;
            .delete_button{
                border: 1px solid #9B9B9B;
                border-radius: 2px;
                color: #707070;
                background: transparent;                
                width: 40px;
                height: 36px;
                font-size: 12px;
                justify-content: center;
                margin: 25px auto 0px auto;
                svg {
                    fill: #9b9b9b;
                    height: 17px;
                    width: 14px;
                }
                &:active {
                    background-color: darkgray;
                    color: white;
                    svg {
                        fill: white;
                    }
                }                
            }
            .share_button {
                border: 1px solid #A2A2A2;
                border-radius: 2px;
                color: #707070;
                background: transparent;                
                width: 205px;
                height: 36px;
                font-size: 12px;
                justify-content: center;
                margin: 25px auto 0px auto;
                svg {
                    // fill: #A2A2A2;
                    // height: 16px;
                    // margin-right: 8px;
                    fill: #A2A2A2;
                    stroke: #A2A2A2;
                    height: 20px;                    
                    padding-bottom: 2px;
                    width: 20%;
                }
                &:active {
                    background-color: darkgray;
                    color: white;                    
                }
            }
            .share_button:active svg {
                stroke: white;
            }            
        }
    }
}
.loading{
    opacity: 0.5; /* Adjust the opacity value as desired */
    pointer-events: none; /* Prevent interaction with the modal while loading */
}
.footer {
    display: flex;
    background-color: $black;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
    gap: 0px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    cursor: pointer;
    .footer_item {
        text-align: center;
        flex-basis: 25%;
        max-width: 100px;
        &.tip_collection{
            margin-left: 40px;
            .tip_collection_icon {
                top: -33%;
                position: absolute;
                background-color: $green;
                height: 45px;
                width: 45px;
                right: 7.8%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;    
                span{
                    font-size: 13px;
                    color: $white;
                    position: absolute;
                    left: 66%;
                    top: 1px;
                    border-radius: 10px;
                }    
            }
        }
        .logo_footer{
            font-weight: 600;
            span{
                color: #CC0000;
            }
        }
        p {
            margin: 0;
            // line-height: 1;
            color: $white;
            font-size: 12px;
        }
        :not(:last-child) p{
            line-height: 21px;
        }
        :last-child p{
            line-height: 1;            
        }
    }
    .active p{
        font-weight: bolder;
    }
}

.screenshot{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #2D2A2B;
    padding: 0 13px 0 0;
    .screenshot_logo{
        background-color: #2D2A2B;
        line-height: 1;
        .logo_footer{
            font-weight: 600;
            span{
                color: #CC0000;
            }
        }
        p {
            margin: 0;
            // line-height: 1;
            color: $white;
            font-size: 12px;
        }
        :not(:last-child) p{
            line-height: 21px;
        }
        :last-child p{
            line-height: 1;            
        }
    }
}

.spb-spinner__shadow--fixed {
    top: -50%;
    bottom: -50%;
    left: -50%;
    right: -50%;
    height: 500%;
    width: 500%;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5000;
  }

  .ua-ios .spb-spinner__shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .spb-spinner__icon {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;

    background-size: 70px;
    background-repeat: no-repeat;

    -webkit-animation: spinnerRotate 1s infinite linear;
    -o-animation: spinnerRotate 1s infinite linear;
    animation: spinnerRotate 1s infinite linear;
  }

  .spb-spinner__triangle {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;

    background-size: 70px;
    background-image: url(data:image/svg+xml;charset=UTF-8,<svg%20width=%221000%22%20height=%221024%22%20xmlns=%22http://www.w3.org/2000/svg%22><path%20fill=%22%23b6b3af%22%20d=%22M939.7%20845.2V845.2L937.6%20841C937.6%20841%20935.5%20839%20935.5%20836.9L535.4%20144.6C529.2%20134.2%20516.8%20125.9%20502.3%20125.9%20485.7%20125.9%20473.3%20134.2%20467%20148.7V148.7L71.1%20834.8C64.9%20843.1%2062.8%20851.4%2062.8%20859.7%2062.8%20880.4%2079.4%20897%20100.1%20897H904.4C925.2%20897%20941.7%20880.4%20941.7%20859.7%20941.7%20853.5%20941.7%20849.3%20939.7%20845.2ZM502.3%20826.5C471.2%20826.5%20448.4%20801.7%20448.4%20770.6%20448.4%20741.5%20471.2%20716.7%20502.3%20716.7S556.2%20741.5%20556.2%20770.6C556.2%20801.7%20533.4%20826.5%20502.3%20826.5ZM556.2%20343.6V644.1C556.2%20644.1%20556.2%20644.1%20556.2%20644.1%20556.2%20656.6%20547.9%20666.9%20535.5%20666.9%20535.5%20666.9%20535.5%20666.9%20535.5%20666.9V666.9H469.1C456.7%20666.9%20448.4%20656.6%20448.4%20644.1%20448.4%20644.1%20448.4%20644.1%20448.4%20644.1V644.1%20345.6C448.4%20345.6%20448.4%20343.6%20448.4%20343.6%20448.4%20333.2%20456.7%20322.8%20467%20322.8V322.8H535.5C547.9%20322.8%20556.2%20331.1%20556.2%20343.6V343.6%20343.6C556.2%20343.6%20556.2%20343.6%20556.2%20343.6Z%22/></svg>);
    background-repeat: no-repeat;
  }

  .spb-spinner__icon--fixed {
    position: fixed;
    z-index: 5000;
  }

  .ua-ios .spb-spinner__icon--fixed {
    position: absolute;
  }

  @-moz-keyframes spinnerRotate {
    from {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spinnerRotate {
    from {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerRotate {
    from {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-first-line {
    fill: #7a7a7a;
  }

  .spb-spinner__icon .spinner-first-line {
    fill: #00ca6b;
  }

  //ligSlider skeleton css
  .lig_slider_item.loading {
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }  

  //Popular Odds skeleton css
  .popular_odds_container.loading {
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }
  
  .popular_match_item.loading {
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }

  .popular_odd_header.loading {
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  .share_icon.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }  

  //teaser slider skeleton css
  .first_slider_item.loading {
    width: 100%;
    min-height: 122px;
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }  

  //SportsMenu skeleton css
  .league_tab_slider.loading {
    display: flex;
    overflow-x: scroll;
  }
  
  .league_tab_slider_item {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--loading-grey);
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 100%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }  
